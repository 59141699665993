<template>
  <v-container>
    <div class="chargeback-statistics">
      <v-row>
        <v-col cols="8" offset="4" sm="6" offset-sm="6" md="3" offset-md="9">
          <v-select
            v-model="selectedPeriodId"
            placeholder="Select Period"
            :items="availablePeriods"
            item-value="id"
            outlined
            dense
            hide-details
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="(statistic, index) in overallStatistics" :key="index" cols="12" sm="6" md="4">
          <number-tile
            :color="statistic.color"
            :title="statistic.title"
            :count="statistic.count"
            :percentage="statistic.percentage"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <line-chart
            chart-title="Events"
            :chart-subtitle="overallChartData.chartSubTitle"
            :chart-data="overallChartData"
          />
        </v-col>
      </v-row>
    </div>

    <v-snackbar v-model="loginAsMerchantErrorSnackbar" :timeout="3000" color="error" top text>
      Can not login as merchant, please try again.
    </v-snackbar>
  </v-container>
</template>

<script>
import store from '@/store';
import { isAdmin } from '@/services/jwt.service';
import dayjs from 'dayjs';
import LineChart from '@/views/dashboards/statistics/charts/LineChart';
import NumberTile from '@/views/dashboards/statistics/tiles/NumberTile';

export default {
  components: {
    LineChart,
    NumberTile,
  },
  setup() {
    isAdmin();
  },
  data: function () {
    return {
      isAllowChargebackStatistics: false,
      isAllowPaymentStatistics: false,
      overallStatistics: [],
      overallChartData: {},
      selectedPeriodId: 2,
      availablePeriods: [
        {
          text: 'Last 7 days',
          id: 1,
          period: {
            from: dayjs().subtract(7, 'd').startOf('d'),
            to: dayjs().endOf('d'),
          },
        },
        {
          text: 'Last 30 days',
          id: 2,
          period: {
            from: dayjs().subtract(30, 'd').startOf('d'),
            to: dayjs().endOf('d'),
          },
        },
        {
          text: 'Last month',
          id: 3,
          period: {
            from: dayjs().subtract(1, 'M').startOf('M'),
            to: dayjs().subtract(1, 'M').endOf('M'),
          },
        },
      ],
      loginAsMerchantErrorSnackbar: false,
    };
  },
  watch: {
    selectedPeriodId(id) {
      return this.filterByPeriod(id);
    },
  },
  methods: {
    getDefaultDataset(label, color) {
      return {
        label: label,
        borderColor: color,
        lineTension: 0.5,
        pointStyle: 'circle',
        backgroundColor: color,
        fill: false,
        pointRadius: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 5,
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: color,
        pointShadowOffsetX: 1,
        pointShadowOffsetY: 1,
        pointShadowBlur: 5,
        pointShadowColor: 'rgba(0, 0, 0, 0.25)',
      };
    },
    assignOverallStatistics(overallStatistics) {
      this.overallStatistics = [
        {
          title: 'Events',
          color: '#836AF9',
          count: overallStatistics.all || 0,
        },
        {
          title: 'Chargebacks Stopped',
          color: '#66BB6A',
          count: overallStatistics.resolved || 0,
          percentage:
            overallStatistics.all > 0 ? Math.round((overallStatistics.resolved / overallStatistics.all) * 100) : 0,
        },
        {
          title: 'Chargebacks Ignored',
          color: '#EF5350',
          count: overallStatistics.ignored || 0,
          percentage:
            overallStatistics.all > 0 ? Math.round((overallStatistics.ignored / overallStatistics.all) * 100) : 0,
        },
        {
          title: 'Pending',
          color: '#558B2F',
          count: overallStatistics.pending || 0,
        },
        {
          title: 'Visa',
          color: '#1A1F71',
          count: overallStatistics.visa || 0,
          percentage:
            overallStatistics.all > 0 ? Math.round((overallStatistics.visa / overallStatistics.all) * 100) : 0,
        },
        {
          title: 'MasterCard',
          color: '#F79E1B',
          count: overallStatistics.masterCard || 0,
          percentage:
            overallStatistics.all > 0 ? Math.round((overallStatistics.masterCard / overallStatistics.all) * 100) : 0,
        },
      ];
    },
    assignOverallChartData(chartSubTitle, chartLabels, statisticsByDate) {
      this.overallChartData = {
        chartSubTitle,
        labels: chartLabels,
        datasets: [
          {
            data: statisticsByDate.map(stats => stats.all || 0),
            ...this.getDefaultDataset('Events', '#836AF9'),
          },
          {
            data: statisticsByDate.map(stats => stats.resolved || 0),
            ...this.getDefaultDataset('Chargebacks Stopped', '#66BB6A'),
          },
          {
            data: statisticsByDate.map(stats => stats.ignored || 0),
            ...this.getDefaultDataset('Chargebacks Ignored', '#EF5350'),
          },
          {
            data: statisticsByDate.map(stats => stats.pending || 0),
            ...this.getDefaultDataset('Pending', '#558B2F'),
          },
          {
            data: statisticsByDate.map(stats => stats.visa || 0),
            ...this.getDefaultDataset('Visa', '#1A1F71'),
          },
          {
            data: statisticsByDate.map(stats => stats.masterCard || 0),
            ...this.getDefaultDataset('MasterCard', '#F79E1B'),
          },
        ],
      };
    },
    fetchEventStatistics(periodLabel, from, to) {
      store.dispatch('chargebackStatistic/fetchEventStatistics', { dateFrom: from, dateTo: to }).then(response => {
        this.assignOverallStatistics(response.data.overallStatistics);
        this.assignOverallChartData(
          periodLabel,
          response.data.dateLabels,
          Object.values(response.data.statisticsByDate),
        );
      });
    },
    filterByPeriod(selectedPeriodId) {
      const selectedPeriod = this.availablePeriods.find(period => selectedPeriodId === period.id);
      if (!selectedPeriod) {
        return false;
      }

      return this.fetchEventStatistics(
        selectedPeriod.text,
        this.formatDate(selectedPeriod.period.from),
        this.formatDate(selectedPeriod.period.to),
      );
    },
    formatDate(date) {
      return date.format('YYYY-MM-DD HH:mm:ss');
    },
  },
  async created() {
    if (
      this.$route.query &&
      this.$route.query.action &&
      this.$route.query.token &&
      (this.$route.query.action === 'login-as-merchant' || this.$route.query.action === 'login-as-reseller')
    ) {
      const loggedIn = await store.dispatch('auth/loginAsMerchant', this.$route.query.token);

      if (!loggedIn) {
        this.loginAsMerchantErrorSnackbar = true;
      }

      window.location.href = '/';
    }
    return this.filterByPeriod(this.selectedPeriodId);
  },
};
</script>
