<template>
  <v-card :color="color" class="dashboard-statistic-tile">
    <v-card-text class="dashboard-statistic-tile__title white--text text-h6 pt-4 pb-0" v-text="title" />
    <v-card-subtitle class="dashboard-statistic-tile__subtitle white--text">
      {{ displayNumber }}
      <span v-if="percentage">({{ displayPercentage }}%)</span>
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    percentage: {
      type: Number,
      required: false,
    },
  },
  data: function () {
    return {
      displayNumber: 0,
      displayPercentage: 0,
      interval: false,
    };
  },
  created() {
    this.interval = window.setInterval(
      function () {
        if (this.displayNumber !== this.count) {
          let change = (this.count - this.displayNumber) / 10;
          change = change >= 0 ? Math.ceil(change) : Math.floor(change);
          this.displayNumber = this.displayNumber + change;
        }

        if (this.percentage && this.displayPercentage !== this.percentage) {
          let percentageChange = (this.percentage - this.displayPercentage) / 10;
          percentageChange = percentageChange >= 0 ? Math.ceil(percentageChange) : Math.floor(percentageChange);
          this.displayPercentage = this.displayPercentage + percentageChange;
        }
      }.bind(this),
      20,
    );
  },
};
</script>

<style lang="scss" scoped>
.dashboard-statistic-tile {
  border-radius: 30px;

  &__subtitle {
    font-size: 36px;
  }
}
</style>
