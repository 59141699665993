<template>
  <v-card>
    <v-card-title v-text="chartTitle" />
    <v-card-subtitle v-text="chartSubtitle" />

    <v-card-text>
      <chart-js-line-chart :key="chartKey" :height="400" :data="chartData" :options="options" />
    </v-card-text>
  </v-card>
</template>

<script>
import ChartJsLineChart from './lib/ChartJsLineChart';

export default {
  components: {
    ChartJsLineChart,
  },
  props: {
    chartData: Object,
    chartTitle: String,
    chartSubtitle: String,
    chartOptions: Object,
  },
  data: function () {
    return {
      chartKey: 0,
    };
  },
  computed: {
    options() {
      return Object.assign(this.getDefaultChartOptions(), this.chartOptions);
    },
  },
  watch: {
    chartData() {
      this.chartKey++;
    },
  },
  methods: {
    getDefaultChartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
              },
              gridLines: {
                color: 'rgba(200, 200, 200, 0.2)',
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
              },
              gridLines: {
                color: 'rgba(200, 200, 200, 0.2)',
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 20,
            boxWidth: 9,
          },
        },
      };
    },
  },
};
</script>
